import { IAction, IState } from "./Login.interfaces";
import { SAVE_AUTHENTICATE_RESPONSE } from "./Login.actions";

const initialState: IState = {
  response: {
    success: null,
    error: null,
    data: {
      twoFactorAuthentication: false,
      accessToken: null,
    },
  },
};

const loginReducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case SAVE_AUTHENTICATE_RESPONSE:
      return { ...state, response: action.payload.response };
    default:
      return state;
  }
};

export default loginReducer;
