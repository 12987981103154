export const RESET_PASSWORD = "RESET_PASSWORD";
export const SAVE_RESET_PASSWORD_RESPONSE = "SAVE_RESET_PASSWORD_RESPONSE";

export const resetPassword = (accountId: string, code: string, password: string) => {
  return {
    payload: { accountId, code, password },
    type: RESET_PASSWORD,
  };
};

export const saveResetPasswordResponse = (response: any) => {
  return {
    payload: { response },
    type: SAVE_RESET_PASSWORD_RESPONSE,
  };
};
