import { IAction, IState } from "./ResetPassword.interfaces";
import { SAVE_RESET_PASSWORD_RESPONSE } from ".//ResetPassword.actions";

const initialState: IState = {
  response: null,
};

const resetPasswordReducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case SAVE_RESET_PASSWORD_RESPONSE:
      return { ...state, response: action.payload.response };
    default:
      return state;
  }
};

export default resetPasswordReducer;
