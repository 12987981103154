import React from "react";
import { Button, Typography, Form, Icon, Input } from "antd";
import styled from "styled-components";
import { logo } from "../ui/logo";
import API from "../../api/Api";
import { Link } from "react-router-dom";

interface IProps {
  form: any;
  history: any;
}

class RequestResetPassword extends React.Component<IProps> {
  public handleSubmit = (event: any) => {
    event.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        API.requestResetPassword(values.email).then(() => {
          // TODO: Temporary solution - change in the near future
          this.props.history.push(`/`);
          alert('Please, check your mailbox to reset your password');
        });
      }
    });
  };

  public render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Container>
        <>
          <Header>
            <div>{logo()}</div>
            <Typography.Title level={4}>Reset password in your account</Typography.Title>
            <Typography.Text type="secondary">Please enter your email to reset password</Typography.Text>
          </Header>
          <Form onSubmit={this.handleSubmit} className="login-form">
            <Form.Item>
              {getFieldDecorator("email", {
                rules: [{ required: true, message: "Please enter your email" }],
              })(<Input prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />} placeholder="Email" />)}
            </Form.Item>
            <SubmitRow>
              <Link to="/">Back to login</Link>
              <Button type="primary" htmlType="submit" className="login-form-button" size="large">
                Reset password
              </Button>
            </SubmitRow>
          </Form>
        </>
      </Container>
    );
  }
}

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 440px;
  padding: 20px;
  .ant-form {
    width: 100%;
  }
`;

const SubmitRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  .login-form-button {
    margin-left: 20px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  div {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
`;

export default Form.create({ name: "request_reset_password" })(RequestResetPassword);
