import { takeEvery, all, call, put } from "redux-saga/effects";
import API from "../../api/Api";
import { saveSetPasswordResponse, SET_PASSWORD } from ".//SetPassword.actions";

function* setPassword(action: any): Generator {
  const { accountId, code, password } = action.payload;
  try {
    const { data } = yield call(API.setPassword, accountId, code, password);
    yield put(saveSetPasswordResponse({ success: true, data, error: null }));
  } catch (error) {
    yield put(saveSetPasswordResponse({ success: false, data: null, error: error.response.data.message }));
  }
}

export default function* root(): Generator {
  yield all([takeEvery(SET_PASSWORD, setPassword)]);
}
