import { takeEvery, all, call, put } from "redux-saga/effects";
import API from "../../api/Api";
import { AUTHENTICATE, saveAuthenticateResponse } from "./Login.actions";

function* authenticate(action: any): Generator {
  const { email, password, code } = action.payload;
  try {
    const { data } = yield call(API.authenticate, email, password, code);
    yield put(saveAuthenticateResponse({ success: true, data, error: null }));
  } catch (error) {
    yield put(
      saveAuthenticateResponse({
        success: false,
        data: {
          twoFactorAuthentication: error.response.data.message.includes("2FA"),
          accessToken: null,
        },
        error: error.response.data.message,
      })
    );
  }
}

export default function* root(): Generator {
  yield all([takeEvery(AUTHENTICATE, authenticate)]);
}
