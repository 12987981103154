import { applyMiddleware, combineReducers, createStore } from "redux";
import createSagaMiddleware from "redux-saga";

import rootSaga from "../saga/index";

import LoginReducer from "../features/login/Login.reducer";
import SetPasswordReducer from "../features/set-password/SetPassword.reducer";
import ResetPasswordReducer from "../features/reset-password/ResetPassword.reducer";
import AccountManagementReducer from "../features/account-management/AccountManagement.reducer";

const rootReducer = combineReducers({
  accountManagement: AccountManagementReducer,
  login: LoginReducer,
  resetPassword: ResetPasswordReducer,
  setPassword: SetPasswordReducer,
});

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);
export { store };
