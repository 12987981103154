import React from "react";
import { Form, Input, Button, Alert, FormItem } from "antd";
import Api from "../../../../api/Api";
interface IProps {
  isEnabled: boolean;
  checkIs2faEnabled: () => Promise<void>;
  regenerateBackupCodes: () => Promise<void>;
  form?: any;
  accountId: string;
}

interface IState {
  error: string;
  isLoading: {
    form: boolean;
  };
}

export class TwoFactorActivationForm extends React.Component<IProps, IState> {
  public state = {
    error: "",
    isLoading: {
      form: false,
    },
  };

  public setError = (error: string) => {
    this.setState({ error });
  };

  public handleSubmit = (event: any) => {
    event.preventDefault();
    this.setError("");
    this.setState({ isLoading: { form: true } });
    const { accountId, isEnabled, form, regenerateBackupCodes, checkIs2faEnabled } = this.props;
    form.validateFields(async (err: any, values: any) => {
      if (!err) {
        try {
          if (isEnabled) {
            await Api.disable2FA(accountId, values.password);
          } else {
            await Api.enable2FA(accountId, values.code);
            regenerateBackupCodes();
          }
          checkIs2faEnabled();
        } catch (e) {
          this.setError(e.response.data.message);
        }
      } else {
        this.setError(isEnabled ? "Password is required" : "Please type 2FA code");
      }
    });
    this.setState({ isLoading: { form: false } });
    this.resetFormFields();
  };

  private resetFormFields() {
    const { form } = this.props;
    if (form) {
      form.resetFields();
    }
  }

  public render() {
    const { isEnabled, form } = this.props;
    const { error, isLoading } = this.state;
    return (
      <>
        <h1>
          2FA is{" "}
          {isEnabled ? (
            <span style={{ color: "#6ab04c" }}>enabled</span>
          ) : (
            <span style={{ color: "#eb4d4b" }}>disabled</span>
          )}
        </h1>
        <div>
          If you want to {isEnabled ? <strong>disable</strong> : <strong>enable</strong>} 2FA, type{" "}
          {isEnabled ? <strong>password</strong> : <strong>code from Google Authenticator</strong>} and click on
          "Confirm" button.
        </div>
        <Form onSubmit={this.handleSubmit}>
          {isEnabled
            ? form.getFieldDecorator("password", {
                initialValue: "",
                rules: [{ required: true, message: "Password is required" }],
              })(<Input placeholder="password" type="password" style={{ margin: "20px 10px 20px 0", width: 150 }} />)
            : form.getFieldDecorator("code", {
                initialValue: "",
                rules: [{ required: true, message: "Please type 2FA code" }],
              })(<Input placeholder="2FA code" type="number" style={{ margin: "20px 10px 20px 0", width: 150 }} />)}

          <Button type="primary" htmlType="submit" loading={isLoading.form}>
            Confirm
          </Button>
        </Form>
        {error && <Alert message={error} type="error" />}
      </>
    );
  }
}
