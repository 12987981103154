import { IAction, IState } from "./AccountManagement.interfaces";
import { GET_ACCOUNT, SAVE_ACCOUNT, SAVE_ACCOUNTS, SAVE_ACCOUNT_ME } from "./AccountManagement.actions";

const initialState: IState = {
  account: null,
  accountMe: null,
  accounts: {
    total: 0,
    offset: 0,
    limit: 25,
    data: [],
  },
};

const accountManagementReducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case SAVE_ACCOUNTS:
      return { ...state, accounts: action.payload.accounts };
    case SAVE_ACCOUNT:
      return { ...state, account: action.payload.account };
    case SAVE_ACCOUNT_ME:
      return { ...state, accountMe: action.payload.account };
    case GET_ACCOUNT:
      return { ...state, account: null };
    default:
      return state;
  }
};

export default accountManagementReducer;
