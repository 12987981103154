export const AUTHENTICATE = "AUTHENTICATE";
export const SAVE_AUTHENTICATE_RESPONSE = "SAVE_AUTHENTICATE_RESPONSE";

export const authenticate = (email: string, password: string, code?: string) => {
  return {
    payload: { email, password, code },
    type: AUTHENTICATE,
  };
};

export const saveAuthenticateResponse = (response: any) => {
  return {
    payload: { response },
    type: SAVE_AUTHENTICATE_RESPONSE,
  };
};
