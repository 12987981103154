import { IAccount, IAccountMe, IAccounts } from "./AccountManagement.interfaces";

export const FIND_ACCOUNTS = "FIND_ACCOUNTS";
export const GET_ACCOUNT = "GET_ACCOUNT";
export const GET_ACCOUNT_2FA_DATA = "GET_ACCOUNT_2FA_DATA";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const DEACTIVATE_ACCOUNT = "DEACTIVATE_ACCOUNT";
export const ACTIVATE_ACCOUNT = "ACTIVATE_ACCOUNT";
export const SAVE_ACCOUNTS = "SAVE_ACCOUNTS";
export const SAVE_ACCOUNT = "SAVE_ACCOUNT";
export const REGISTER_ACCOUNT = "REGISTER_ACCOUNT";
export const EDIT_ACCOUNT = "EDIT_ACCOUNT";
export const EDIT_ACCOUNT_ROLE = "EDIT_ACCOUNT_ROLE";
export const REQUEST_RESET_PASSWORD = "REQUEST_RESET_PASSWORD";
export const GET_BACKUP_CODES_DATA = "GET_BACKUP_CODES_DATA";
export const GET_ACCOUNT_ME = "GET_ACCOUNT_ME";
export const SAVE_ACCOUNT_ME = "SAVE_ACCOUNT_ME";

export const findAccounts = (limit: number, offset: number, roles: string[]) => {
  return {
    payload: {
      limit,
      offset,
      roles,
    },
    type: FIND_ACCOUNTS,
  };
};

export const getAccount = (id: string) => {
  return {
    payload: { id },
    type: GET_ACCOUNT,
  };
};

export const getAccount2FaData = (id: string, updateLoadingStatus: () => void) => {
  return {
    payload: { id, updateLoadingStatus },
    type: GET_ACCOUNT_2FA_DATA,
  };
};

export const registerAccount = (
  email: string,
  firstName: string,
  lastName: string,
  position: string,
  onSuccess?: () => {},
  onFailed?: (error: any) => {}
) => {
  return {
    payload: {
      email,
      firstName,
      lastName,
      position,
      onSuccess,
      onFailed,
    },
    type: REGISTER_ACCOUNT,
  };
};

export const editAccount = (id: string, email: string, firstName: string, lastName: string, position: string) => {
  return {
    payload: {
      email,
      firstName,
      id,
      lastName,
      position,
    },
    type: EDIT_ACCOUNT,
  };
};

export const editAccountRole = (id: string, role: string) => {
  return {
    payload: {
      id,
      role,
    },
    type: EDIT_ACCOUNT_ROLE,
  };
};

export const deleteAccount = (id: string) => {
  return {
    payload: {
      id,
    },
    type: DELETE_ACCOUNT,
  };
};

export const deactivateAccount = (id: string) => {
  return {
    payload: {
      id,
    },
    type: DEACTIVATE_ACCOUNT,
  };
};

export const activateAccount = (id: string) => {
  return {
    payload: {
      id,
    },
    type: ACTIVATE_ACCOUNT,
  };
};

export const requestResetPassword = (email: string) => {
  return {
    payload: {
      email,
    },
    type: REQUEST_RESET_PASSWORD,
  };
};

export const saveAccounts = (accounts: IAccounts) => {
  return {
    payload: { accounts },
    type: SAVE_ACCOUNTS,
  };
};

export const saveAccount = (account: IAccount | null) => {
  return {
    payload: { account },
    type: SAVE_ACCOUNT,
  };
};

export const getBackupCodesData = (id: string) => {
  return {
    payload: { id },
    type: GET_BACKUP_CODES_DATA,
  };
};

export const getAccountMe = () => {
  return {
    payload: {},
    type: GET_ACCOUNT_ME,
  };
};

export const saveAccountMe = (account: IAccountMe | null) => {
  return {
    payload: { account },
    type: SAVE_ACCOUNT_ME,
  };
};
