import * as React from "react";
import { Form, Row, Col, Spin, message } from "antd";
import { IState as IAccountManagementState } from "../AccountManagement.interfaces";
import styled from "styled-components";
import Api from "../../../api/Api";
import GoogleAuthenticatorConfigurationSteps from "./two-factor-authentication/google-authenticator-configuration-steps.component";
import { TwoFactorActivationForm } from "./two-factor-authentication/2fa-activation-form.component";
import { BackupCodes } from "./two-factor-authentication/backup-codes.component";

interface IProps {
  form?: any;
  history: any;
  account: IAccountManagementState["account"];
  get2FaData: (id: string, updateLoadingStatus: () => void) => void;
  getBackupCodesData: (id: string) => void;
}

interface IState {
  isLoading: {
    qrCode: boolean;
    backupCodes: boolean;
    regenerateBackupCodes: boolean;
    page: boolean;
  };
  error: string;
  showBackupCodes: boolean;
}

class TwoFactorAuthentication extends React.Component<IProps, IState> {
  public state = {
    isLoading: {
      qrCode: false,
      backupCodes: false,
      regenerateBackupCodes: false,
      page: false,
    },
    showBackupCodes: false,
    error: "",
  };

  async componentDidMount() {
    this.setState({ isLoading: { ...this.state.isLoading, page: true, qrCode: true } });
    await this.getAccountData();
    this.setState({ isLoading: { ...this.state.isLoading, page: false, qrCode: false } });
  }

  public getAccountData = () => {
    return new Promise((resolve) => {
      const { get2FaData } = this.props;
      const accountId = this.getAccountId();
      get2FaData(accountId, resolve);
    });
  };

  public getAccountId = () => {
    return this.props.history.location.pathname.split("/").reverse()[0];
  };

  public regenerateBackupCodes = async () => {
    this.setState({
      isLoading: { ...this.state.isLoading, regenerateBackupCodes: true, backupCodes: true },
      showBackupCodes: false,
    });
    try {
      const accountId = this.getAccountId();
      await Api.regenerateBackupCodes(accountId);
      await this.props.getBackupCodesData(accountId);
    } catch (error) {
      message.error("Generating new backup codes failed", 5);
    }
    this.setState({
      isLoading: { ...this.state.isLoading, regenerateBackupCodes: false },
      showBackupCodes: true,
      backupCodes: false,
    });
  };

  public render() {
    const { isLoading, showBackupCodes } = this.state;
    const { account, form } = this.props;
    const accountId = this.getAccountId();
    return (
      <>
        {isLoading.page && !account && (
          <SpinnerWrapper>
            <Spin />
          </SpinnerWrapper>
        )}
        {!isLoading.page && account && (
          <Row gutter={[16, 16]}>
            {!account.twoFactorAuthentication.isEnabled ? (
              <>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <GoogleAuthenticatorConfigurationSteps
                    isLoading={isLoading.qrCode}
                    qrCode={account.twoFactorAuthentication.qrCode}
                  />
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <TwoFactorActivationForm
                    checkIs2faEnabled={this.getAccountData}
                    isEnabled={account.twoFactorAuthentication.isEnabled}
                    regenerateBackupCodes={this.regenerateBackupCodes}
                    form={form}
                    accountId={accountId}
                  />
                </Col>
              </>
            ) : (
              <>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <TwoFactorActivationForm
                    checkIs2faEnabled={this.getAccountData}
                    isEnabled={account.twoFactorAuthentication.isEnabled}
                    regenerateBackupCodes={this.regenerateBackupCodes}
                    form={form}
                    accountId={accountId}
                  />
                </Col>
                <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                  <BackupCodes
                    regenerateBackupCodes={this.regenerateBackupCodes}
                    backupCodes={account.twoFactorAuthentication.backupCodes}
                    isLoading={isLoading.regenerateBackupCodes}
                    showBackupCodes={showBackupCodes}
                  />
                </Col>
              </>
            )}
          </Row>
        )}
      </>
    );
  }
}

export default Form.create({ name: "2fa" })(TwoFactorAuthentication);

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;
