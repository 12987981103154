import React from "react";
import { Alert } from "antd";

const Message = ({ type, message, timeout, isDisappear }) => {
  const [show, setShow] = React.useState(true);
  const [timeId, setTimeId] = React.useState(null);

  React.useEffect(() => {
    if (isDisappear) {
      const timeId = setTimeout(() => {
        setShow(false);
      }, timeout);
      setTimeId(timeId);
    }

    return () => {
      if (isDisappear) clearTimeout(timeId);
    };
  }, []);

  if (!show) {
    return null;
  }
  return <Alert message={message} type={type} />;
};

export default Message;
