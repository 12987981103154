import React from "react";
import moment from "moment";

import { IAccount, IAccounts } from "src/features/account-management/AccountManagement.interfaces";
import { Button, Icon, Table, Tag, Modal, Input, Dropdown, Menu, Select } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Highlighter from "react-highlight-words";

interface IProps {
  findAccounts: (limit: number, offset: number, roles: string[]) => void;
  deactivateAccount: (id: string) => void;
  activateAccount: (id: string) => void;
  deleteAccount: (id: string) => void;
  accounts: IAccounts;
}

interface IState {
  searchText: string;
  accountStatuses: string;
  currentPage: number;
  roles: string[];
  limit: number;
}

export default class List extends React.Component<IProps, IState> {
  public state = {
    searchText: "",
    accountStatuses: "all",
    currentPage: 0,
    roles: ["admin", "user"],
    limit: 25,
  };

  public componentDidMount(): void {
    const { currentPage, limit, roles } = this.state;
    this.props.findAccounts(limit, currentPage, roles);
  }

  public getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node: any) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}>
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered: any) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value: any, record: any) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text: string) => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  public handleSearch = (selectedKeys: any, confirm: any) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  public handleReset = (clearFilters: any) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  public updateAccountStatuses = (status: string): void => {
    this.setState({ accountStatuses: status });
  };

  public filterAccounts = (accounts: IAccount[]): IAccount[] => {
    switch (this.state.accountStatuses) {
      case "all":
        return accounts;
      case "active":
        return accounts.filter((acct: IAccount) => acct.isActive);
      case "inactive":
        return accounts.filter((acct: IAccount) => !acct.isActive);
      default:
        return accounts;
    }
  };

  public onPaginationChange = (currentPage: number) => {
    const { limit, roles } = this.state;

    this.setState({ currentPage }, () => {
      this.props.findAccounts(limit, (currentPage - 1) * limit, roles);
    });
  };

  public render() {
    const { currentPage } = this.state;
    const { accounts } = this.props;

    const showDisableConfirm = (id: string) => {
      Modal.confirm({
        cancelText: "No",
        okText: "Yes",
        okType: "danger",
        onCancel: () => {},
        onOk: () => {
          this.props.deactivateAccount(id);
        },
        title: "Are you sure you want to deactivate this account?",
      });
    };

    const showEnableConfirm = (id: string) => {
      Modal.confirm({
        cancelText: "No",
        okText: "Yes",
        okType: "primary",
        onCancel: () => {},
        onOk: () => {
          this.props.activateAccount(id);
        },
        title: "Are you sure you want to activate this account?",
      });
    };

    const showRemoveConfirm = (id: string) => {
      Modal.confirm({
        cancelText: "No",
        okText: "Yes",
        okType: "danger",
        onCancel: () => {},
        onOk: () => {
          this.props.deleteAccount(id);
        },
        title:
          "Are you sure you want to remove this account? The data associated with this account will be permanently deleted",
      });
    };

    const columns = [
      {
        dataIndex: "email",
        title: "Email",
        ...this.getColumnSearchProps("email"),
      },
      {
        dataIndex: "firstName",
        title: "First name",
      },
      {
        dataIndex: "lastName",
        title: "Last name",
      },
      {
        dataIndex: "role",
        title: "Role",
      },
      {
        dataIndex: "position",
        render: (position: string) => (position ? <Tag>{position}</Tag> : "-"),
        title: "Position",
      },
      {
        dataIndex: "isActive",
        render: (isActive: boolean) => (
          <Tag color={isActive ? "green" : "red"}>{isActive ? "activated" : "deactivated"}</Tag>
        ),
        title: "Active",
      },
      {
        dataIndex: "failedLoginAttempts",
        render: (failedLoginAttempts: string[]) =>
          failedLoginAttempts.length >= 5 ? (
            <Tag color="red">
              <Icon type="lock" /> locked
            </Tag>
          ) : (
            "-"
          ),
        title: "Locked",
      },
      {
        dataIndex: "isPasswordSet",
        render: (isPasswordSet: boolean) => (
          <Tag color={isPasswordSet ? "blue" : "red"}>{isPasswordSet ? "set" : "not set"}</Tag>
        ),
        title: "Password",
      },
      {
        dataIndex: "twoFactorAuthentication",
        render: (twoFactorAuthentication: { isEnabled: boolean }) =>
          twoFactorAuthentication.isEnabled ? <Tag color="green">enabled</Tag> : <Tag color="red">disabled</Tag>,
        title: "2FA",
      },
      {
        dataIndex: "createdAt",
        render: (createdAt: string) => moment(createdAt).format("YYYY-MM-DD"),
        title: "Created at",
      },
      {
        render: (account: IAccount) => {
          const menu = (
            <Menu>
              <Menu.Item key="1">
                <Link to={`/accounts/edit/${account.id}`}>Edit</Link>
              </Menu.Item>
              <Menu.Item
                key="2"
                onClick={() => (account.isActive ? showDisableConfirm(account.id) : showEnableConfirm(account.id))}>
                {account.isActive ? "Deactivate" : "Activate"}
              </Menu.Item>
              <Menu.Item key="3" onClick={() => showRemoveConfirm(account.id)}>
                Remove
              </Menu.Item>
            </Menu>
          );
          return (
            <Dropdown overlay={menu}>
              <Button>
                Actions <Icon type="down" />
              </Button>
            </Dropdown>
          );
        },
        title: "",
      },
    ];

    const displayedData = this.filterAccounts(accounts.data);

    return (
      <>
        <ButtonRow>
          <Select
            value={this.state.accountStatuses}
            style={{ width: 200 }}
            onChange={(e: any): void => this.updateAccountStatuses(e)}>
            <Select.Option value="active">Active</Select.Option>
            <Select.Option value="inactive">Inactive</Select.Option>
            <Select.Option value="all">All</Select.Option>
          </Select>
          <Link to="/accounts/register">
            <Button htmlType="button" type="primary">
              <Icon type="plus" /> Add new account
            </Button>
          </Link>
        </ButtonRow>
        <Table
          columns={columns}
          dataSource={displayedData}
          size="middle"
          pagination={{
            pageSize: accounts.limit,
            current: currentPage,
            total: accounts.total,
            onChange: this.onPaginationChange,
            showSizeChanger: false,
          }}
        />
      </>
    );
  }
}

const ButtonRow = styled.div`
  padding: 0px 20px 0px 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
