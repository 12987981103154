import React from "react";
import { Steps, Spin } from "antd";
import styled from "styled-components";

interface IProps {
  isLoading: boolean;
  qrCode: string;
}

const GoogleAuthenticatorConfigurationSteps = (props: IProps) => {
  const { qrCode, isLoading } = props;
  return (
    <>
      <h1 style={{ marginBottom: 20 }}>Google Authenticator Configuration</h1>
      <Steps direction="vertical">
        <Steps.Step
          status="process"
          title="Download Google Authenticator App"
          description={
            <StepDescription>
              Download application from{" "}
              <a target="_blank" href="https://apps.apple.com/us/app/google-authenticator/id388497605">
                <strong> App Store</strong>
              </a>{" "}
              or{" "}
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en">
                <strong> Google Play</strong>
              </a>
              .
            </StepDescription>
          }
        />
        <Steps.Step
          status="process"
          title="Scan QR code by Google Authenticator App"
          description={
            <StepDescription>
              {isLoading ? (
                <SpinnerWrapper>
                  <Spin />
                </SpinnerWrapper>
              ) : (
                <img width="200px" src={qrCode} alt="QR code" />
              )}
            </StepDescription>
          }
        />
        <Steps.Step
          status="process"
          title="That's all"
          description={
            <StepDescription>
              Now you can enable/disable 2FA. <br />
              Don't forget to download backup codes.
            </StepDescription>
          }
        />
      </Steps>
    </>
  );
};

export default GoogleAuthenticatorConfigurationSteps;

const StepDescription = styled.div`
  padding: 10px 0 10px;
`;

const SpinnerWrapper = styled.div`
  float: left;
  padding: 10px 70px 10px;
`;
