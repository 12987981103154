import React from "react";
import { Table, Icon, Switch, Button, notification, Alert } from "antd";
import { IAccount } from "../../AccountManagement.interfaces";
import Api from "../../../../api/Api";

interface IProps {
  form?: any;
  account: IAccount | null;
}

interface IState {
  error: string;
  isLoading: boolean;
  permissions: string[];
}

export class PermissionsForm extends React.Component<IProps, IState> {
  public state = {
    error: "",
    isLoading: false,
    permissions: [],
  };

  public componentDidMount(): void {
    const { account } = this.props;
    this.setState({ permissions: account ? account.permissions : [] });
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.account !== this.props.account) {
      if (this.props.account) {
        this.setState({ permissions: this.props.account.permissions });
      }
    }
  }

  public setError = (error: string) => {
    this.setState({ error });
  };

  public handleSubmit = async (event: any) => {
    const { account } = this.props;
    const { permissions } = this.state;
    event.preventDefault();
    this.setError("");
    this.setState({ isLoading: true });
    try {
      await Api.updatePermissions(account.id, permissions);
      notification.success({
        message: "Success",
        description: "Successfully changed permissions",
      });
    } catch (e) {
      this.setError(e.response.data.message);
    }
    this.setState({ isLoading: false });
  };

  public onChange = (checked: boolean, value: string) => {
    let permissions = this.state.permissions;
    if (checked) permissions.push(value);
    else
      permissions = permissions.filter((el) => {
        return el != value;
      });
    this.setState({ permissions });
  };

  public render() {
    const { permissions, isLoading, error } = this.state;

    const columns = [
      {
        title: "Property",
        dataIndex: "property",
        key: "property",
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Is active?",
        dataIndex: "isActive",
        key: "isActive",
        render: (value, record) => {
          return (
            <Switch
              checkedChildren={<Icon type="check" />}
              unCheckedChildren={<Icon type="close" />}
              onChange={(checked) => this.onChange(checked, record.value)}
              defaultChecked={permissions.includes(record.value)}
              checked={permissions.includes(record.value)}
            />
          );
        },
      },
    ];

    const data = [
      {
        property: "MANAGEMENT PERMISSION",
        description: "This user will have the ability to manage all users' permissions",
        value: "MANAGEMENT_PERMISSION",
      },
      {
        property: "RESET PATIENT PASSWORD",
        description: "This user will have the ability to reset the patients' passwords",
        value: "RESET_PATIENT_PASSWORD",
      },
      {
        property: "CHANGE PATIENT EMAILS",
        description: "This user will have the ability to change the patients' emails",
        value: "CHANGE_PATIENT_EMAIL",
      },
      {
        property: "ACTIVATE PATIENT ACCOUNT",
        description: "This user will have the ability to activate the patients' account",
        value: "ACTIVATE_PATIENT_ACCOUNT",
      },
    ];

    return (
      <>
        {error && <Alert message={error} type="error" />}
        <Table columns={columns} dataSource={data} />
        <Button
          type="primary"
          htmlType="button"
          loading={isLoading}
          style={{ marginBottom: 24 }}
          onClick={this.handleSubmit}>
          Save
        </Button>
      </>
    );
  }
}
