import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { IStore } from "../../store/store.interface";
import styled from "styled-components";
import { IState as IAccountManagementState } from "./AccountManagement.interfaces";
import {
  deleteAccount,
  registerAccount,
  requestResetPassword,
  editAccount,
  getAccount,
  editAccountRole,
  deactivateAccount,
  activateAccount,
  getAccount2FaData,
  getBackupCodesData,
  findAccounts,
  getAccountMe,
} from "./AccountManagement.actions";
import { Route, Switch } from "react-router-dom";
import List from "./components/List.component";
import Register from "./components/Register.component";
import Edit from "./components/Edit.component";
import TwoFactorAuthentication from "./components/TwoFactorAuthentication.component";

interface IProps {
  findAccounts: (limit: number, offset: number, roles: string[]) => void;
  getAccount: (id: string) => void;
  getAccount2FaData: (id: string, updateLoadingStatus: () => void) => void;
  editAccountRole: (id: string, role: string) => void;
  deleteAccount: (id: string) => void;
  deactivateAccount: (id: string) => void;
  activateAccount: (id: string) => void;
  requestResetPassword: (email: string) => void;
  registerAccount: (
    email: string,
    firstName: string,
    lastName: string,
    position: string,
    onSucces?: () => void,
    onFailed?: (error: any) => void
  ) => void;
  editAccount: (id: string, email: string, firstName: string, lastName: string) => void;
  accounts: IAccountManagementState["accounts"];
  account: IAccountManagementState["account"];
  history: any;
  getBackupCodesData: (id: string) => void;
  getAccountMe: () => void;
}

class AccountManagement extends React.Component<IProps> {
  state = {
    registerForm: {
      loading: false,
      errors: {
        email: undefined,
        firstName: undefined,
        lastName: undefined,
      },
    },
  };

  private setLoading = (value: boolean) => {
    const newState = { ...this.state };
    newState.registerForm.loading = value;

    this.setState(newState);
  };

  private whetherErrorsContainPhrase(phrase: string, errors: string[]): string | undefined {
    return errors.find((error: string) => error.includes(phrase));
  }

  public render() {
    return (
      <Container>
        <Switch>
          <Route exact path="/accounts">
            <List
              findAccounts={this.props.findAccounts}
              deleteAccount={this.props.deleteAccount}
              activateAccount={this.props.activateAccount}
              deactivateAccount={this.props.deactivateAccount}
              accounts={this.props.accounts}
            />
          </Route>
          <Route path="/accounts/register">
            <Register
              setLoading={this.setLoading}
              loading={this.state.registerForm.loading}
              formErrors={this.state.registerForm.errors}
              clearError={(fieldName: "firstName" | "lastName" | "email") => {
                this.setState((state) => ({
                  ...state,
                  registerForm: {
                    ...this.state.registerForm,
                    errors: {
                      ...this.state.registerForm.errors,
                      [fieldName]: undefined,
                    },
                  },
                }));
              }}
              registerAccount={(email: string, firstName: string, lastName: string, position: string) => {
                this.props.registerAccount(
                  email,
                  firstName,
                  lastName,
                  position,
                  () => {
                    this.props.history.push("/accounts");

                    this.setState((state) => ({
                      ...state,
                      registerForm: {
                        ...this.state.registerForm,
                        loading: false,
                        errors: {
                          ...this.state.registerForm.errors,
                          firstName: undefined,
                          lastName: undefined,
                          email: undefined,
                        },
                      },
                    }));
                  },
                  (errors) => {
                    this.setState((state) => ({
                      ...state,
                      registerForm: {
                        ...this.state.registerForm,
                        loading: false,
                        errors: {
                          ...this.state.registerForm.errors,
                          firstName: this.whetherErrorsContainPhrase("first name", errors),
                          lastName: this.whetherErrorsContainPhrase("last name", errors),
                          email: this.whetherErrorsContainPhrase("email", errors),
                        },
                      },
                    }));
                  }
                );
              }}
            />
          </Route>
          <Route path="/accounts/edit/:id">
            <Edit
              history={this.props.history}
              account={this.props.account}
              accountMe={this.props.accountMe}
              editAccount={(id: string, email: string, firstName: string, lastName: string, position: string) => {
                this.props.editAccount(id, email, firstName, lastName, position);
              }}
              requestResetPassword={(email: string) => {
                this.props.requestResetPassword(email);
              }}
              editAccountRole={(id: string, role: string) => {
                this.props.editAccountRole(id, role);
              }}
              getAccount={(id: string) => {
                this.props.getAccount(id);
              }}
              getAccountMe={() => {
                this.props.getAccountMe();
              }}
            />
          </Route>

          <Route path="/accounts/2fa/:id">
            <TwoFactorAuthentication
              history={this.props.history}
              account={this.props.account}
              get2FaData={this.props.getAccount2FaData}
              getBackupCodesData={this.props.getBackupCodesData}
            />
          </Route>
        </Switch>
      </Container>
    );
  }
}

const Container = styled.div``;

const mapStateToProps = (store: IStore) => {
  return store.accountManagement;
};

const mapDispatchToProps = {
  activateAccount,
  deactivateAccount,
  deleteAccount,
  editAccount,
  editAccountRole,
  getAccount,
  findAccounts,
  registerAccount,
  requestResetPassword,
  getAccount2FaData,
  getBackupCodesData,
  getAccountMe,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AccountManagement);
