export const SET_PASSWORD = "SET_PASSWORD";
export const SAVE_SET_PASSWORD_RESPONSE = "SAVE_SET_PASSWORD_RESPONSE";

export const setPassword = (accountId: string, code: string, password: string) => {
  return {
    payload: { accountId, code, password },
    type: SET_PASSWORD,
  };
};

export const saveSetPasswordResponse = (response: any) => {
  return {
    payload: { response },
    type: SAVE_SET_PASSWORD_RESPONSE,
  };
};
