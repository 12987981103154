import { Icon } from "antd";
import React from "react";
import styled from "styled-components";

const PasswordPolicyMessage = (props: { password?: string }) => {
  const passwordText = props.password || "";
  const successIcon = <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" style={{ marginRight: 3 }} />;
  const failIcon = <Icon type="close-circle" theme="twoTone" twoToneColor="#FAAD12" style={{ marginRight: 3 }} />;

  const containsLowerCaseLetter = /[a-z]/.test(passwordText);
  const containsUpperCaseLetter = /[A-Z]/.test(passwordText);
  const containsNumberLetter = /\d/.test(passwordText);
  const containsSpecialLetter = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(passwordText);
  const is8Characters = passwordText.length >= 8;

  return (
    <Container>
      <p>Password must contain:</p>
      <ul>
        <li>
          {is8Characters ? successIcon : failIcon} <span>at least 8 characters</span>
        </li>
        <li>
          {containsLowerCaseLetter ? successIcon : failIcon} <span>a minimum of 1 lower case letter [a-z]</span>
        </li>
        <li>
          {containsUpperCaseLetter ? successIcon : failIcon} <span>a minimum of 1 upper case letter [A-Z]</span>
        </li>
        <li>
          {containsNumberLetter ? successIcon : failIcon} <span>a minimum of 1 numeric character [0-9]</span>
        </li>
        <li>
          {containsSpecialLetter ? successIcon : failIcon} <span>a minimum of 1 special character</span>
        </li>
      </ul>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  text-align: left;
  border: 1px solid #f3f3f3;
  padding: 20px;
  border-radius: 8px;
  margin: 25px 0;
  p {
    display: block;
    font-weight: bold;
    margin-bottom: 15px;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      display: flex;
      align-items: center;
      padding: 0;
      span {
        display: block;
        margin-left: 4px;
        line-height: 2;
      }
    }
  }
`;

export default PasswordPolicyMessage;
