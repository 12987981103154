import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import { authenticate, saveAuthenticateResponse } from "./Login.actions";
import { IStore } from "../../store/store.interface";
import { Button, Typography, Form, Icon, Input, Spin } from "antd";
import { IState as ILoginState } from ".//Login.interfaces";
import styled from "styled-components";
import { logo } from "../ui/logo";
import { Link } from "react-router-dom";
import API from "../../api/Api";
import Message from "../ui/Message";

interface IProps {
  response: ILoginState["response"];
  form: any;
  authenticate: (email: string, password: string, code?: string) => void;
  saveAuthenticateResponse: (response: any) => void;
}

interface IState {
  loading: boolean;
  alert: {
    isActive: boolean;
    type: string;
    message: any;
    timeout: number;
    isDisappear: boolean;
  };
  is2faActive: boolean;
  timer: any;
}

class Login extends React.Component<IProps, IState> {
  public state: IState = {
    loading: false,
    alert: {
      isActive: false,
      type: "",
      message: null,
      timeout: 0,
      isDisappear: false,
    },
    is2faActive: false,
    timer: null,
  };

  public handleSubmit = (event: any) => {
    event.preventDefault();
    this.props.saveAuthenticateResponse({
      success: null,
      error: null,
      data: {
        twoFactorAuthentication: false,
        accessToken: null,
      },
    });
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        this.setState({ loading: true });
        this.props.authenticate(values.email, values.password, values.code);
      }
    });
  };

  public async componentDidUpdate(prevProps: IProps): void {
    if (prevProps.response === this.props.response) return;
    this.setState({
      alert: {
        isActive: false,
        type: "",
        message: null,
        timeout: 0,
        isDisappear: false,
      },
      is2faActive: false,
      timer: null,
    });

    this.setState({ loading: false });
    const { response } = this.props || {};
    if (response) {
      if (response.success === true && response.data) {
        const { accessToken, passwordExpiresAtDays, twoFactorAuthentication } = response.data || {};
        if (twoFactorAuthentication) {
          this.setState({ is2faActive: true });
        }
        if (accessToken) {
          if (passwordExpiresAtDays <= 14) {
            console.log(passwordExpiresAtDays);
            this.setState({
              alert: {
                isActive: true,
                type: "warning",
                message: (
                  <>
                    <b>Authentication successful! Loading...</b>
                    <br />
                    Please update your password. You have {response.data.passwordExpiresAtDays} days before your
                    password is expired.
                  </>
                ),
                timeout: 4000,
                isDisappear: true,
              },
            });
          }

          const decodedAccessToken: any = jwt_decode(accessToken);
          if (!["admin", "user"].includes(decodedAccessToken.data.role)) {
            await API.unAuthenticate();
            this.setState({
              alert: {
                isActive: true,
                type: "info",
                message: <>Login Error - Invalid permissions</>,
                timeout: 4000,
                isDisappear: true,
              },
            });
            return;
          }

          const searchParams = new URLSearchParams(window.location.search);

          let targetURL = searchParams.get("target") as string;
          const urlObject = new URL(targetURL);

          if (!urlObject.host.includes(".2020onsite.com")) {
            targetURL = process.env.ADMIN_PANEL_URL as string;
          }

          if (passwordExpiresAtDays > 14) {
            this.setState({
              alert: {
                isActive: true,
                type: "success",
                message: (
                  <>
                    <b>Authentication successful! Loading...</b>
                  </>
                ),
                timeout: 0,
                isDisappear: false,
              },
            });
            window.location.href = targetURL;
          } else {
            const timer = setTimeout(() => (window.location.href = targetURL), 5000);
            this.setState({ timer });
          }
          return;
        }
        return;
      } else if (response.success === false) {
        this.setState({
          alert: {
            isActive: true,
            type: "error",
            message: <>{response.error || "Unexpected error"}</>,
            timeout: 0,
            isDisappear: false,
          },
        });
        return;
      }
    }
  }
  public async componentWillUnmount() {
    const { time } = this.state;
    if (time) clearTimeout(time);
  }
  public render() {
    const { response, form } = this.props;
    const { getFieldDecorator } = form;
    const { loading, alert, is2faActive } = this.state;
    return (
      <Container>
        <Header>
          <div>{logo()}</div>
          <Typography.Title level={4}>Log in to your account</Typography.Title>
        </Header>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <Form.Item>
            {getFieldDecorator("email", {
              rules: [{ required: true, message: "Please input your email!" }],
            })(
              <Input
                disabled={this.state.loading || response.data.twoFactorAuthentication}
                prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Email"
              />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("password", {
              rules: [{ required: true, message: "Please input your password!" }],
            })(
              <Input
                disabled={this.state.loading || response.data.twoFactorAuthentication}
                prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder="Password"
              />
            )}
          </Form.Item>

          {is2faActive && (
            <Form.Item>
              {getFieldDecorator("code", {
                rules: [{ required: true, message: "Please input 2FA code" }],
              })(
                <Input
                  disabled={this.state.loading}
                  prefix={<Icon type="key" style={{ color: "rgba(0,0,0,.25)" }} />}
                  type="number"
                  placeholder="2FA code"
                />
              )}
            </Form.Item>
          )}
          {alert && alert.isActive && (
            <AlertRow>
              <Message message={alert.message} type={alert.type} />
            </AlertRow>
          )}

          <SubmitRow>
            <Link to="/request-reset-password">Forgot password</Link>
            <Button type="primary" htmlType="submit" className="login-form-button" size="large" loading={loading}>
              Log in
            </Button>
          </SubmitRow>
        </Form>
      </Container>
    );
  }
}

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 440px;
  padding: 20px;
  .ant-form {
    width: 100%;
  }
`;

const SubmitRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  .login-form-button {
    margin-left: 20px;
  }
`;

const AlertRow = styled.div`
  flex-basis: 100%;
  margin: 0 0 15px 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  div {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const mapStateToProps = (store: IStore) => {
  return {
    response: store.login.response,
  };
};

const mapDispatchToProps = {
  authenticate,
  saveAuthenticateResponse,
};
export default compose(connect(mapStateToProps, mapDispatchToProps), Form.create({ name: "login" }))(Login);
