import React from "react";
import { Button, Card, Form, Icon, Input, Select, Typography, Alert, Modal } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { IAccount, IAccountMe } from "src/features/account-management/AccountManagement.interfaces";
import Api from "../../../api/Api";
import { PermissionsForm } from "./permissions/permissions-form.component";

interface IProps {
  editAccount: (id: string, email: string, firstName: string, lastName: string, position: string) => void;
  requestResetPassword: (email: string) => void;
  getAccount: (id: string) => void;
  editAccountRole: (id: string, role: string) => void;
  account: IAccount | null;
  accountMe: IAccountMe | null;
  form?: any;
  history: any;
  getAccountMe: (id: string) => void;
}

interface IState {
  detailsLoading: boolean;
  requestResetPasswordLoading: boolean;
  roleLoading: boolean;
  id?: string;
  role?: string;
}

class Edit extends React.Component<IProps, IState> {
  public state = {
    detailsLoading: false,
    id: undefined,
    requestResetPasswordLoading: false,
    role: undefined,
    roleLoading: false,
  };

  public handleSubmit = (event: any) => {
    this.setState({ detailsLoading: false });
    event.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        this.setState({ detailsLoading: true });
        this.props.editAccount(
          this.state.id || "",
          values.email,
          values.firstName,
          values.lastName,
          values.position || ""
        );
        setTimeout(() => {
          this.setState({ detailsLoading: false });
        }, 1500);
      }
    });
  };

  public componentDidMount(): void {
    const id = this.props.history.location.pathname.split("/").reverse()[0];
    this.setState({ id });

    this.props.getAccount(id);
    this.props.getAccountMe();
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (prevProps.account !== this.props.account) {
      if (this.props.account) {
        this.setState({ role: this.props.account.role });
      }
    }
  }

  public resetFailedLoginAttempts = async () => {
    const { id } = this.state;
    await Api.resetFailedLoginAttempts(id);
    this.props.getAccount(id);
  };

  public editRole = () => {
    this.setState({ roleLoading: true });
    this.props.editAccountRole(this.state.id || "", this.state.role || "");
    setTimeout(() => {
      this.setState({ roleLoading: false });
    }, 1500);
  };

  public requestResetPassword = () => {
    if (!this.props.account) {
      return;
    }
    this.setState({ requestResetPasswordLoading: true });
    this.props.requestResetPassword(this.props.account.email);
    setTimeout(() => {
      this.setState({ requestResetPasswordLoading: false });
    }, 1500);
  };

  public render() {
    const { account, accountMe } = this.props;

    const hasAccessToManagementPermission = accountMe && accountMe.permissions?.includes("MANAGEMENT_PERMISSION");

    return (
      <>
        <ButtonRow>
          <Link to="/accounts">
            <Button htmlType="button" type="default">
              <Icon type="arrow-left" /> Back to list
            </Button>
          </Link>
        </ButtonRow>
        <Typography.Title level={3}>Edit account</Typography.Title>

        {account && account.failedLoginAttempts.length >= 5 && (
          <Alert
            message="Account is locked"
            description={
              <div>
                <div>
                  User entered the password incorrectly many times. To unlock the account click on the below button.
                </div>
                <div style={{ marginTop: 10 }}>
                  <Button
                    onClick={() =>
                      Modal.confirm({
                        title: "Are you sure?",
                        content: "User will be able to login again",
                        okText: "Yes",
                        cancelText: "Cancel",
                        onOk: this.resetFailedLoginAttempts,
                      })
                    }>
                    Unlock account
                  </Button>
                </div>
              </div>
            }
            type="error"
          />
        )}

        <Card size="small" title="Edit details" style={{ margin: "50px 0" }}>
          <Form onSubmit={this.handleSubmit} style={{ maxWidth: 500, margin: "24px 0" }}>
            <Form.Item>
              {this.props.form.getFieldDecorator("email", {
                initialValue: this.props.account ? this.props.account.email : undefined,
                rules: [{ required: true, message: "Please input email!" }],
              })(<Input disabled={this.state.detailsLoading} placeholder="Email" />)}
            </Form.Item>
            <Form.Item>
              {this.props.form.getFieldDecorator("firstName", {
                initialValue: this.props.account ? this.props.account.firstName : undefined,
                rules: [{ required: true, message: "Please input first name!" }],
              })(<Input disabled={this.state.detailsLoading} placeholder="First name" />)}
            </Form.Item>
            <Form.Item>
              {this.props.form.getFieldDecorator("lastName", {
                initialValue: this.props.account ? this.props.account.lastName : undefined,
                rules: [{ required: true, message: "Please input last name!" }],
              })(<Input disabled={this.state.detailsLoading} placeholder="Last name" />)}
            </Form.Item>
            {this.props.account && this.props.account.role !== "patient" && (
              <Form.Item>
                {this.props.form.getFieldDecorator("position", {
                  initialValue: this.props.account ? this.props.account.position : undefined,
                  rules: [
                    {
                      required: false,
                      message: `Please select user's position!`,
                    },
                  ],
                })(
                  <Select style={{ width: "100%" }} disabled={this.state.detailsLoading}>
                    <Select.Option value="">-</Select.Option>
                    <Select.Option value="ASM">ASM</Select.Option>
                    <Select.Option value="CS">CS</Select.Option>
                    <Select.Option value="SM">SM</Select.Option>
                    <Select.Option value="OD">OD</Select.Option>
                  </Select>
                )}
              </Form.Item>
            )}

            <Button type="primary" htmlType="submit" loading={this.state.detailsLoading}>
              Save
            </Button>
          </Form>
        </Card>
        {this.props.account ? (
          <Card size="small" title="Reset password" style={{ margin: "50px 0" }}>
            <div style={{ maxWidth: 500, marginBottom: 24, marginTop: 24 }}>
              <Input
                prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                disabled={true}
                type="password"
                value={this.props.account.isPasswordSet ? "xxxxxxxxxxx" : ""}
                placeholder="Password not set"
              />
            </div>
            <Button
              type="primary"
              htmlType="button"
              loading={this.state.requestResetPasswordLoading}
              style={{ marginBottom: 10 }}
              onClick={this.requestResetPassword}>
              Reset password
            </Button>
            <div style={{ marginBottom: 24 }}>
              <small>When a password reset is requested, an email will be sent with further instructions.</small>
            </div>
          </Card>
        ) : null}
        {this.state.role ? (
          <Card size="small" title="Edit role" style={{ margin: "50px 0" }}>
            <div style={{ maxWidth: 500, marginBottom: 24, marginTop: 24 }}>
              <Select
                defaultValue={this.state.role}
                style={{ width: 250 }}
                onChange={(role: string) => this.setState({ role })}>
                <Select.Option value="admin">Admin</Select.Option>
                <Select.Option value="user">User</Select.Option>
                <Select.Option value="patient">Patient</Select.Option>
              </Select>
            </div>
            <Button
              type="primary"
              htmlType="button"
              loading={this.state.roleLoading}
              style={{ marginBottom: 24 }}
              onClick={this.editRole}>
              Save
            </Button>
          </Card>
        ) : null}
        {hasAccessToManagementPermission && (
          <Card size="small" title="Edit permissions" style={{ margin: "50px 0" }}>
            <PermissionsForm account={account} />
          </Card>
        )}
      </>
    );
  }
}

const ButtonRow = styled.div`
  text-align: left;
  margin-bottom: 20px;
`;

export default Form.create({ name: "edit" })(Edit);
