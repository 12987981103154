import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { IStore } from "../../store/store.interface";
import { Button, Typography, Form, Icon, Input, Alert } from "antd";
import styled from "styled-components";
import { logo } from "../ui/logo";
import { IState as IStateResetPasswordState } from ".//ResetPassword.interfaces";
import { saveResetPasswordResponse, resetPassword } from ".//ResetPassword.actions";
import qs from "query-string";
import { CONFIG } from "../../config";
import { Link } from "react-router-dom";
import PasswordPolicyMessage from "../ui/PasswordPolicyMessage";

interface IProps {
  response: IStateResetPasswordState["response"];
  form: any;
  location: any;
  resetPassword: (accountId: string, code: string, password: string) => void;
  saveResetPasswordResponse: (response: any) => void;
}

interface IState {
  loading: boolean;
  accountId: string | null;
}

class ResetPassword extends React.Component<IProps, IState> {
  public state = {
    loading: false,
    accountId: null,
  };

  public componentDidMount = () => {
    const accountId = this.getAccountIdFromParam();
    this.setState({ accountId });
  };

  public componentDidUpdate(prevProps: IProps): void {
    if (prevProps.response !== this.props.response) {
      this.setState({ loading: false });
    }
  }

  public handleSubmit = (event: any) => {
    event.preventDefault();
    this.props.saveResetPasswordResponse(null);
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        this.setState({ loading: true });
        const { accountId } = qs.parse(this.props.location.search);
        this.props.resetPassword(accountId as string, values.code.trim(), values.password);
      }
    });
  };

  public getAccountIdFromParam = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get("accountId");
  };

  public render() {
    const { accountId } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { password: passwordText } = this.props.form.getFieldsValue();

    if (!accountId) {
      return (
        <Container>
          <div style={{ marginBottom: 30 }}>{logo()}</div>
          <Alert
            message="Invalid link"
            description="Link does not contain accountId parameter."
            type="error"
            showIcon
          />
        </Container>
      );
    }

    return (
      <Container>
        {this.props.response && this.props.response.success ? (
          <a href={CONFIG.adminUrl} target="_parent">
            <Button type="primary" htmlType="button" className="login-form-button" size="large">
              Log in to account
            </Button>
          </a>
        ) : (
          <>
            <Header>
              <div>{logo()}</div>
              <Typography.Title level={4}>Reset password in your account</Typography.Title>
              <Typography.Text type="secondary">
                A six letter confirmation code has been sent to your email
              </Typography.Text>
            </Header>
            <Form onSubmit={this.handleSubmit} className="login-form">
              <Form.Item label={"6 LETTER CODE"}>
                {getFieldDecorator("code", {
                  rules: [{ required: true, message: "Please enter six letter code" }],
                })(
                  <Input
                    autoComplete={"new-password"}
                    disabled={this.state.loading}
                    prefix={<span style={{ color: "rgba(0,0,0,.25)" }}>#</span>}
                    placeholder="6 letter code"
                    onFocus={() => this.props.saveResetPasswordResponse(null)}
                    style={{ width: "50%" }}
                  />
                )}
              </Form.Item>
              <Form.Item label={"NEW PASSWORD"}>
                {getFieldDecorator("password", {
                  rules: [{ required: true, message: "Please enter your new password" }],
                })(
                  <Input
                    autoComplete={"new-password"}
                    disabled={this.state.loading}
                    prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                    type="password"
                    placeholder="New password"
                    onFocus={() => this.props.saveResetPasswordResponse(null)}
                  />
                )}
              </Form.Item>
              <Form.Item label={"CONFIRM NEW PASSWORD"}>
                {getFieldDecorator("repassword", {
                  rules: [
                    { required: true, message: "Please enter your new password again" },
                    {
                      validator: (rule: any, value: string, callback: (err?: any) => void) => {
                        if (value !== passwordText) {
                          callback("Your password and confirmation password do not match");
                        } else {
                          callback();
                        }
                      },
                    },
                  ],
                })(
                  <Input
                    autoComplete={"new-password"}
                    disabled={this.state.loading}
                    prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                    type="password"
                    placeholder="Confirm new password"
                    onFocus={() => this.props.saveResetPasswordResponse(null)}
                  />
                )}
              </Form.Item>
              <PasswordPolicyMessage password={passwordText} />
              <SubmitRow>
                {this.props.response && this.props.response.success === false ? (
                  <ErrorRow>
                    <Alert
                      message={`Cannot reset password. Please make sure that confirmation code is valid or password is strong enough`}
                      type="error"
                    />
                  </ErrorRow>
                ) : null}
                <Link to="/">Back to login</Link>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  size="large"
                  loading={this.state.loading}>
                  Reset password
                </Button>
              </SubmitRow>
            </Form>
          </>
        )}
      </Container>
    );
  }
}

const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 440px;
  padding: 20px;
  .ant-form {
    width: 100%;
  }
`;

const SubmitRow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  .login-form-button {
    margin-left: 20px;
  }
`;

const ErrorRow = styled.div`
  flex-basis: 100%;
  margin: 0 0 15px 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  div {
    flex-basis: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const mapStateToProps = (store: IStore) => {
  return {
    response: store.resetPassword.response,
  };
};

const mapDispatchToProps = {
  resetPassword,
  saveResetPasswordResponse,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  Form.create({ name: "reset_password" })
)(ResetPassword);
