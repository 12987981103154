import { SagaIterator } from "redux-saga";
import { all, fork } from "redux-saga/effects";

import login from "../features/login/Login.saga";
import setPassword from "../features/set-password/SetPassword.saga";
import resetPassword from "../features/reset-password/ResetPassword.saga";
import accountManagement from "../features/account-management/AccountManagement.saga";

export default function* root(): SagaIterator {
  yield all([fork(login), fork(setPassword), fork(resetPassword), fork(accountManagement)]);
}
