import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  ACTIVATE_ACCOUNT,
  DEACTIVATE_ACCOUNT,
  DELETE_ACCOUNT,
  EDIT_ACCOUNT,
  EDIT_ACCOUNT_ROLE,
  FIND_ACCOUNTS,
  GET_ACCOUNT,
  GET_ACCOUNT_2FA_DATA,
  GET_ACCOUNT_ME,
  GET_BACKUP_CODES_DATA,
  getAccount,
  REGISTER_ACCOUNT,
  REQUEST_RESET_PASSWORD,
  saveAccount,
  saveAccountMe,
  saveAccounts,
} from "./AccountManagement.actions";
import API from "../../api/Api";

function* findAccountsAsync(action: any): Generator {
  try {
    const { data } = yield call(API.findAccounts, action.payload);
    yield put(saveAccounts(data));
  } catch (error) {
    yield put(saveAccounts({ total: 0, offset: 0, limit: 25, data: [] }));
  }
}

function* editAccountAsync(action: any): Generator {
  const { id, email, firstName, lastName, position } = action.payload;
  try {
    yield call(API.updateAccount, id, email, firstName, lastName, position || undefined);
    yield put(getAccount(id));
  } catch (error) {
    yield put(getAccount(id));
  }
}

function* editAccountRoleAsync(action: any): Generator {
  const { id, role } = action.payload;
  try {
    yield call(API.updateAccountRole, id, role);
    yield put(getAccount(id));
  } catch (error) {
    yield put(getAccount(id));
  }
}

function* requestResetPasswordAsync(action: any): Generator {
  const { email } = action.payload;
  try {
    yield call(API.requestResetPassword, email);
  } catch (error) {}
}

function* getAccountAsync(action: any): Generator {
  try {
    const { id } = action.payload;
    const { data } = yield call(API.getAccount, id);
    yield put(saveAccount(data));
  } catch (error) {
    yield put(saveAccount(null));
  }
}

function* deleteAccountAsync(action: any): Generator {
  try {
    yield call(API.deleteAccount, action.payload.id as string);
    yield put(getAccounts());
  } catch (error) {
    yield put(getAccounts());
  }
}

function* activateAccountAsync(action: any): Generator {
  try {
    yield call(API.activateAccount, action.payload.id as string);
    yield put(getAccounts());
  } catch (error) {
    yield put(getAccounts());
  }
}

function* deactivateAccountAsync(action: any): Generator {
  try {
    yield call(API.deactivateAccount, action.payload.id as string);
    yield put(getAccounts());
  } catch (error) {
    yield put(getAccounts());
  }
}

function* registerAccountAsync(action: any): Generator {
  const { email, firstName, lastName, position, onSuccess, onFailed } = action.payload;

  try {
    yield call(API.registerAccount, email, firstName, lastName, position || undefined);
    onSuccess?.();
    // @ts-ignore
  } catch (error: Error) {
    const errorResponseMessage = error.response?.data?.message;
    const errorMessage = Array.isArray(errorResponseMessage) ? errorResponseMessage : [errorResponseMessage];

    onFailed?.(errorMessage);
  }
}

function* getAccount2FaDataAsync(action: any): Generator {
  try {
    const { id, updateLoadingStatus } = action.payload;
    const accountResponse = yield call(API.getAccount, id);

    let qrCodeResponse;
    if (!accountResponse.data.twoFactorAuthentication.isEnabled) {
      qrCodeResponse = yield call(API.getQrCode, id);
    }

    yield put(
      saveAccount({
        ...accountResponse.data,
        twoFactorAuthentication: {
          ...accountResponse.data.twoFactorAuthentication,
          qrCode: qrCodeResponse ? qrCodeResponse.data.qrCode : undefined,
          backupCodes: [],
        },
      })
    );
    updateLoadingStatus();
  } catch (error) {
    yield put(saveAccount(null));
  }
}

function* getBackupCodesDataAsync(action: any): Generator {
  try {
    const { id } = action.payload;
    const backupCodesResponse = yield call(API.getBackupCodes, id);
    const accountResponse = yield call(API.getAccount, id);
    yield put(
      saveAccount({
        ...accountResponse.data,
        twoFactorAuthentication: {
          ...accountResponse.data.twoFactorAuthentication,
          backupCodes: backupCodesResponse.data.backupCodes,
        },
      })
    );
  } catch (error) {
    yield put(saveAccount(null));
  }
}

function* getAccountMeAsync(action: any): Generator {
  try {
    const { data } = yield call(API.getAccountMe);
    yield put(saveAccountMe(data.data));
  } catch (error) {
    yield put(saveAccountMe(null));
  }
}

export default function* root(): Generator {
  yield all([
    takeEvery(FIND_ACCOUNTS, findAccountsAsync),
    takeEvery(GET_ACCOUNT, getAccountAsync),
    takeEvery(DELETE_ACCOUNT, deleteAccountAsync),
    takeEvery(ACTIVATE_ACCOUNT, activateAccountAsync),
    takeEvery(DEACTIVATE_ACCOUNT, deactivateAccountAsync),
    takeEvery(REGISTER_ACCOUNT, registerAccountAsync),
    takeEvery(EDIT_ACCOUNT, editAccountAsync),
    takeEvery(EDIT_ACCOUNT_ROLE, editAccountRoleAsync),
    takeEvery(REQUEST_RESET_PASSWORD, requestResetPasswordAsync),
    takeEvery(GET_ACCOUNT_2FA_DATA, getAccount2FaDataAsync),
    takeEvery(GET_BACKUP_CODES_DATA, getBackupCodesDataAsync),
    takeEvery(GET_ACCOUNT_ME, getAccountMeAsync),
  ]);
}
