import React from "react";
import { Button, Form, Icon, Input, Typography, Select } from "antd";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface IProps {
  registerAccount: (email: string, firstName: string, lastName: string, position: string) => void;
  form?: any;
  formErrors?: { [key: string]: string };
  loading?: boolean;
  setLoading: (value: boolean) => void;
  clearError: (fieldName: string) => void;
}

interface IState {
  loading: boolean;
}

class Register extends React.Component<IProps, IState> {
  public handleSubmit = (event: any) => {
    event.preventDefault();
    this.props.setLoading(false);
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        this.props.setLoading(true);

        this.props.registerAccount(values.email, values.firstName, values.lastName, values.position);
      }
    });
  };

  componentDidUpdate() {
    const firstNameError = this.props.formErrors?.firstName;
    const lastNameError = this.props.formErrors?.lastName;
    const emailError = this.props.formErrors?.email;

    if (firstNameError && !(this.props.form.getFieldError("firstName") || []).includes(firstNameError)) {
      this.props.form.setFields({
        firstName: {
          value: this.props.form.getFieldValue("firstName"),
          errors: [new Error(firstNameError)],
        },
      });
    }

    if (!firstNameError && (this.props.form.getFieldError("firstName") || []).includes(firstNameError)) {
      this.props.form.setFields({
        firstName: {
          value: this.props.form.getFieldValue("firstName"),
          errors: [],
        },
      });
    }

    if (lastNameError && !(this.props.form.getFieldError("lastName") || []).includes(lastNameError)) {
      this.props.form.setFields({
        lastName: {
          value: this.props.form.getFieldValue("lastName"),
          errors: [new Error(lastNameError)],
        },
      });
    }

    if (!lastNameError && (this.props.form.getFieldError("lastName") || []).includes(lastNameError)) {
      this.props.form.setFields({
        lastName: {
          value: this.props.form.getFieldValue("lastName"),
          errors: [],
        },
      });
    }

    if (emailError && !(this.props.form.getFieldError("email") || []).includes(emailError)) {
      this.props.form.setFields({
        email: {
          value: this.props.form.getFieldValue("email"),
          errors: [new Error(emailError)],
        },
      });
    }

    if (!emailError && (this.props.form.getFieldError("email") || []).includes(emailError)) {
      this.props.form.setFields({
        email: {
          value: this.props.form.getFieldValue("email"),
          errors: [],
        },
      });
    }
  }

  public render() {
    return (
      <>
        <ButtonRow>
          <Link to="/accounts">
            <Button htmlType="button" type="default">
              <Icon type="arrow-left" /> Back to list
            </Button>
          </Link>
        </ButtonRow>
        <Typography.Title level={3}>Register new account</Typography.Title>
        <Form onSubmit={this.handleSubmit} style={{ maxWidth: 500, marginTop: 30 }}>
          <Form.Item>
            {this.props.form.getFieldDecorator("email", {
              rules: [{ required: true, message: "Please input email!" }],
            })(
              <Input
                disabled={this.props.loading}
                placeholder="Email"
                onChange={() => {
                  this.props.clearError("email");
                }}
              />
            )}
          </Form.Item>
          <Form.Item>
            {this.props.form.getFieldDecorator("firstName", {
              rules: [{ required: true, message: "Please input first name!" }],
            })(
              <Input
                disabled={this.props.loading}
                placeholder="First name"
                onChange={() => {
                  this.props.clearError("firstName");
                }}
              />
            )}
          </Form.Item>
          <Form.Item>
            {this.props.form.getFieldDecorator("lastName", {
              rules: [{ required: true, message: "Please input last name!" }],
            })(
              <Input
                disabled={this.props.loading}
                placeholder="Last name"
                onChange={() => {
                  this.props.clearError("lastName");
                }}
              />
            )}
          </Form.Item>
          <Form.Item>
            {this.props.form.getFieldDecorator("position", {
              rules: [{ required: false, message: `Please select user's position!` }],
            })(
              <Select placeholder="Select user's position" disabled={this.props.loading} style={{ width: "100%" }}>
                <Select.Option value="">-</Select.Option>
                <Select.Option value="ASM">ASM</Select.Option>
                <Select.Option value="CS">CS</Select.Option>
                <Select.Option value="SM">SM</Select.Option>
                <Select.Option value="OD">OD</Select.Option>
              </Select>
            )}
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={this.props.loading}>
            Register
          </Button>
        </Form>
      </>
    );
  }
}

const ButtonRow = styled.div`
  text-align: left;
  margin-bottom: 20px;
`;

export default Form.create({ name: "register" })(Register);
