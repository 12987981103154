import axios from "axios";
import { CONFIG } from "../config";

axios.defaults.withCredentials = true;

export default class API {
  public static URL = CONFIG.apiUrl;

  public static authenticate(email: string, password: string, code?: string) {
    return axios.post(
      `${API.URL}/authorization/accounts/authenticate`,
      { email, password, code },
      { headers: API.headers }
    );
  }

  public static unAuthenticate() {
    return axios.post(`${API.URL}/authorization/accounts/unauthenticate`, { headers: API.headers });
  }

  public static setPassword(accountId: string, code: string, password: string) {
    return axios.post(`${API.URL}/authorization/accounts/${accountId}/set-password`, { code, password });
  }

  public static resetPassword(accountId: string, code: string, password: string) {
    return axios.post(`${API.URL}/authorization/accounts/${accountId}/reset-password`, { code, password });
  }

  public static resetFailedLoginAttempts(accountId: string) {
    return axios.post(
      `${API.URL}/authorization/accounts/${accountId}/reset-failed-login-attempts`,
      {},
      { headers: API.headers }
    );
  }

  public static requestResetPassword(email: string) {
    return axios.post(
      `${API.URL}/authorization/accounts/${email}/request-reset-password`,
      {},
      { headers: API.headers }
    );
  }

  public static regenerateBackupCodes(id: string) {
    return axios.post(
      `${API.URL}/authorization/accounts/${id}/two-factor-authentication/regenerate-backup-codes`,
      {},
      { headers: API.headers }
    );
  }

  public static enable2FA(id: string, code: string) {
    return axios.post(
      `${API.URL}/authorization/accounts/${id}/two-factor-authentication/enable`,
      { code },
      { headers: API.headers }
    );
  }

  public static disable2FA(id: string, password: string) {
    return axios.post(
      `${API.URL}/authorization/accounts/${id}/two-factor-authentication/disable`,
      { password },
      { headers: API.headers }
    );
  }

  public static getAccounts() {
    return axios.get(`${API.URL}/authorization/accounts`, { headers: API.headers });
  }

  public static findAccounts(params: any) {
    return axios.get(`${API.URL}/authorization/find-accounts`, { headers: API.headers, params });
  }

  public static getAccount(id: string) {
    return axios.get(`${API.URL}/authorization/accounts/${id}`, { headers: API.headers });
  }

  public static getQrCode(id: string) {
    return axios.get(`${API.URL}/authorization/accounts/${id}/two-factor-authentication/get-qr-code`, {
      headers: API.headers,
    });
  }

  public static getBackupCodes(id: string) {
    return axios.get(`${API.URL}/authorization/accounts/${id}/two-factor-authentication/get-backup-codes`, {
      headers: API.headers,
    });
  }

  public static updateAccount(id: string, email: string, firstName: string, lastName: string, position?: string) {
    return axios.patch(
      `${API.URL}/authorization/accounts/${id}`,
      { email, firstName, lastName, position },
      { headers: API.headers }
    );
  }

  public static updateAccountRole(id: string, role: string) {
    return axios.post(`${API.URL}/authorization/accounts/${id}/set-role`, { role }, { headers: API.headers });
  }

  public static deleteAccount(accountId: string) {
    return axios.delete(`${API.URL}/authorization/accounts/${accountId}`, { headers: API.headers });
  }

  public static registerAccount(email: string, firstName: string, lastName: string, position?: string) {
    return axios.post(
      `${API.URL}/authorization/accounts/register-user-account`,
      { email, firstName, lastName, position },
      { headers: API.headers }
    );
  }

  public static activateAccount(accountId: string) {
    return axios.post(`${API.URL}/authorization/accounts/${accountId}/activate`, {}, { headers: API.headers });
  }

  public static deactivateAccount(accountId: string) {
    return axios.post(`${API.URL}/authorization/accounts/${accountId}/deactivate`, {}, { headers: API.headers });
  }

  public static getAccountMe() {
    return axios.get(`${API.URL}/authorization/accounts/me`, { headers: API.headers });
  }

  public static updatePermissions(accountId: string, permissions: string[]) {
    return axios.put(
      `${API.URL}/authorization/accounts/${accountId}/permissions`,
      { permissions },
      { headers: API.headers }
    );
  }

  public static get headers() {
    return {
      "Content-Type": "application/json",
    };
  }
}
