import { IAction, IState } from "./SetPassword.interfaces";
import { SAVE_SET_PASSWORD_RESPONSE } from ".//SetPassword.actions";

const initialState: IState = {
  response: null,
};

const setPasswordReducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case SAVE_SET_PASSWORD_RESPONSE:
      return { ...state, response: action.payload.response };
    default:
      return state;
  }
};

export default setPasswordReducer;
