import React from "react";

export const logo = () => (
  <svg width="362" height="50" viewBox="0 0 543 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 79.3307H42.2106V68.8619H18.9957C29.5469 60.952 40.4531 49.5293 40.4531 38.7173C40.4531 27.4749 31.4848 21.5193 20.6659 21.5193C16.934 21.5606 13.2506 22.3706 9.84606 23.8987C6.44148 25.4268 3.38881 27.6401 0.878782 30.4004L6.85566 38.1066C11.5115 33.89 16.0916 32.1336 19.6998 32.1336C25.3275 32.1336 28.0512 35.6232 28.0512 39.7758C28.0512 47.1622 19.2575 56.212 0 71.7641V79.3307Z"
      fill="#14292E"
    />
    <path
      d="M71.3273 80.209C84.7127 80.209 94.0243 68.0768 94.0243 50.8555C94.0243 33.6342 84.7127 21.4961 71.3273 21.4961C57.8721 21.5194 48.5488 33.6284 48.5488 50.8555C48.5488 68.0826 57.8604 80.209 71.3273 80.209ZM71.3273 69.6587C64.3785 69.6587 60.7703 61.9292 60.7703 50.8555C60.7703 39.7817 64.3785 32.0464 71.3273 32.0464C78.183 32.0464 81.7039 39.7817 81.7039 50.8555C81.7039 61.9292 78.2121 69.6587 71.3273 69.6587"
      fill="#14292E"
    />
    <path d="M94.1924 81H103.073L130.252 18.4194H121.277L94.1924 81Z" fill="#14292E" />
    <path
      d="M130.944 79.3307H173.155V68.8619H149.952C160.503 60.9521 171.409 49.5294 171.409 38.7173C171.409 27.4691 162.441 21.4902 151.622 21.4902C147.89 21.5312 144.206 22.341 140.802 23.8691C137.397 25.3972 134.345 27.6108 131.835 30.3713L137.812 38.1067C142.467 33.89 147.048 32.1336 150.656 32.1336C156.284 32.1336 159.007 35.6232 159.007 39.7759C159.007 47.1622 150.214 56.212 130.956 71.7641L130.944 79.3307Z"
      fill="#14292E"
    />
    <path
      d="M202.277 80.209C215.662 80.209 224.974 68.0768 224.974 50.8555C224.974 33.6342 215.662 21.4961 202.277 21.4961C188.891 21.4961 179.504 33.6284 179.504 50.8555C179.504 68.0826 188.816 80.209 202.277 80.209ZM202.277 69.6587C195.334 69.6587 191.725 61.9292 191.725 50.8555C191.725 39.7817 195.334 32.0464 202.277 32.0464C209.22 32.0464 212.659 39.7817 212.659 50.8555C212.659 61.9292 209.138 69.6587 202.277 69.6587Z"
      fill="#14292E"
    />
    <path
      d="M317.176 79.3309H329.729V50.0588C329.729 40.7938 335.718 34.3555 343.976 34.3555C353.363 34.3555 355.395 39.6655 355.395 48.82V79.3309H367.948V47.238C367.948 32.3199 362.972 22.2639 347.817 22.2639C340.356 22.2639 333.914 25.1719 329.729 31.4125V23.3922H317.176V79.3309Z"
      fill="#14292E"
    />
    <path
      d="M396.569 80.4591C406.969 80.4591 420.43 75.7132 420.43 62.6039C420.43 51.0765 409.576 46.9005 399.176 44.6381C394.2 43.6203 389.562 42.713 389.562 38.8744C389.562 35.9373 393.747 34.0122 397.931 34.0122C403.307 33.9926 408.587 35.4383 413.202 38.1939L417.858 28.0275C411.753 24.4099 406.218 22.2638 397.844 22.2638C388.008 22.2638 376.817 28.138 376.817 39.1303C376.817 50.1226 385.849 53.2226 396.453 56.1655C401.993 57.7475 407.65 58.7595 407.65 62.8307C407.65 66.7856 401.656 68.7049 396.592 68.7049C388.224 68.7049 382.456 64.9768 378.836 62.0397L374.087 72.3225C377.143 75.2596 385.058 80.4649 396.592 80.4649L396.569 80.4591ZM427.751 79.3308H440.305V23.3921H427.751V79.3308ZM433.996 15.2555C436.015 15.2603 437.955 14.4691 439.394 13.0536C440.834 11.6381 441.656 9.71248 441.684 7.69462C441.666 5.66151 440.85 3.71664 439.413 2.27787C437.975 0.839106 436.03 0.0212407 433.996 0C431.977 0.0275549 430.05 0.849713 428.634 2.28807C427.217 3.72643 426.425 5.66495 426.43 7.68299C426.43 9.68825 427.227 11.6114 428.646 13.0293C430.065 14.4473 431.989 15.2438 433.996 15.2438"
      fill="#14292E"
    />
    <path
      d="M470.748 80.4591C475.549 80.4338 480.234 78.9778 484.203 76.2774L480.473 66.8845C480.473 66.8845 476.736 69.5947 472.325 69.5947C469.159 69.5947 466.331 67.6696 466.331 61.6849V33.6749H483.208V23.3921H466.331V9.8291L453.777 12.4289V23.3921H445.531V33.6749H453.783V61.3592C453.783 74.2418 459.324 80.4591 470.748 80.4591"
      fill="#14292E"
    />
    <path
      d="M517.445 80.4592C525.038 80.4019 532.44 78.0811 538.705 73.794L533.729 64.5233C528.984 67.7131 523.391 69.4069 517.672 69.3855C509.303 69.3855 502.07 64.7326 500.26 55.8225H542.662C542.869 54.2116 542.982 52.5901 543 50.9661C543 35.2628 531.017 22.2639 516.089 22.2639C499.794 22.2639 487.252 35.3733 487.252 51.4197C487.252 69.8391 501.499 80.4592 517.445 80.4592V80.4592ZM500.143 46.5575C501.843 38.5372 508.174 33.2213 515.408 33.2213C523.102 33.2213 529.545 38.5372 530.79 46.5575H500.143Z"
      fill="#14292E"
    />
    <path
      d="M276.945 21.1296C253.258 21.1296 242.876 50.2855 242.876 50.2855C242.876 50.2855 253.258 79.3657 276.945 79.3657C302.389 79.3657 311.008 50.3145 311.008 50.3145C311.008 50.3145 302.913 21.1587 276.945 21.1587"
      fill="#14292E"
    />
    <path
      d="M276.945 67.3557C289.457 67.3557 297.226 54.8919 298.71 50.1984C297.302 45.5455 289.666 33.1108 276.945 33.1108C266.382 33.1108 257.611 44.9348 255.458 50.2798C257.623 55.5957 266.393 67.3557 276.945 67.3557Z"
      fill="#F0EDE2"
    />
    <path
      d="M285.802 49.9074C285.802 51.6329 285.29 53.3196 284.331 54.7542C283.372 56.1889 282.008 57.3071 280.413 57.9674C278.818 58.6277 277.063 58.8005 275.369 58.4638C273.676 58.1272 272.12 57.2963 270.9 56.0763C269.679 54.8562 268.847 53.3017 268.511 51.6094C268.174 49.9171 268.347 48.163 269.007 46.5689C269.668 44.9747 270.787 43.6122 272.222 42.6536C273.658 41.695 275.346 41.1833 277.072 41.1833C278.219 41.1833 279.354 41.409 280.413 41.8474C281.472 42.2859 282.435 42.9285 283.245 43.7386C284.056 44.5487 284.699 45.5104 285.138 46.5689C285.576 47.6273 285.802 48.7618 285.802 49.9074"
      fill="#14292E"
    />
    <path
      d="M287.798 41.8755C287.799 42.7327 287.546 43.571 287.07 44.2843C286.595 44.9976 285.918 45.5539 285.126 45.8827C284.334 46.2116 283.462 46.2982 282.62 46.1317C281.779 45.9652 281.006 45.5529 280.399 44.9472C279.792 44.3415 279.378 43.5694 279.211 42.7287C279.043 41.8881 279.128 41.0166 279.456 40.2245C279.784 39.4324 280.34 38.7553 281.053 38.279C281.766 37.8026 282.605 37.5483 283.463 37.5483C284.612 37.5483 285.713 38.004 286.526 38.8154C287.339 39.6267 287.797 40.7273 287.798 41.8755"
      fill="#F0EDE2"
    />
  </svg>
);
