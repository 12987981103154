import { hot } from "react-hot-loader";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "antd/dist/antd.css";
import "./features/ui/styles/index.css";
import SetPassword from "./features/set-password/SetPassword.container";
import ResetPassword from "./features/reset-password/ResetPassword.container";
import RequestResetPassword from "./features/request-reset-password/RequestResetPassword.container";
import AccountManagement from "./features/account-management/AccountManagement.container";
import Login from "./features/login/Login.container";

class App extends React.Component {
  public render() {
    return (
      <Router>
        <Switch>
          <Route path="/set-password" component={SetPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/request-reset-password" component={RequestResetPassword} />
          <Route path="/accounts" component={AccountManagement} />
          <Route path="/" component={Login} />
        </Switch>
      </Router>
    );
  }
}

export default hot(module)(App);
