import React from "react";
import { Alert, Button, Modal } from "antd";
import styled from "styled-components";

interface IProps {
  backupCodes: string[];
  regenerateBackupCodes: () => Promise<void>;
  showBackupCodes: boolean;
  isLoading: boolean;
}
export class BackupCodes extends React.Component<IProps> {
  public render() {
    const { regenerateBackupCodes, showBackupCodes, backupCodes, isLoading } = this.props;
    return (
      <>
        <h1 style={{ marginBottom: 20 }}>Backup Codes</h1>
        {showBackupCodes && (
          <>
            <div style={{ marginBottom: 20 }}>
              Below is the list of backup codes, <strong> keep it in safe place (e.g. in password manager)</strong>.
              <strong> This list disappears when you go to another page - Save it!</strong> The codes allow you to log
              in to the application without using Google Authenticator App (e.g. in an emergency).
            </div>
            <BackupCodesWrapper
              message={
                <>
                  {backupCodes.map((code, index) => {
                    return (
                      <div key={index}>
                        <strong>{code}</strong>
                      </div>
                    );
                  })}
                </>
              }
              type="info"
            />
          </>
        )}
        <div>
          You can regenerate backup codes at any time clicking on button below. <br />
          <Button
            style={{ marginTop: 10 }}
            loading={isLoading}
            onClick={() =>
              Modal.confirm({
                title: "Do you want to regenerate backup codes?",
                content: "New codes will replace previously generated. Keep it in safe place!",
                onOk: regenerateBackupCodes,
                okText: "Yes",
                cancelText: "Cancel",
              })
            }>
            Regenerate backup codes
          </Button>
        </div>
      </>
    );
  }
}

const BackupCodesWrapper = styled(Alert)`
  text-align: center;
  margin-bottom: 20px;
`;
