import { takeEvery, all, call, put } from "redux-saga/effects";
import API from "../../api/Api";
import { saveResetPasswordResponse, RESET_PASSWORD } from ".//ResetPassword.actions";

function* resetPassword(action: any): Generator {
  const { accountId, code, password } = action.payload;
  try {
    const { data } = yield call(API.resetPassword, accountId, code, password);
    yield put(saveResetPasswordResponse({ success: true, data, error: null }));
  } catch (error) {
    yield put(saveResetPasswordResponse({ success: false, data: null, error: error.response.data.message }));
  }
}

export default function* root(): Generator {
  yield all([takeEvery(RESET_PASSWORD, resetPassword)]);
}
